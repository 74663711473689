import type { JWT } from '@/api/client'
import type { User } from '@/domain/user'

import { defineStore } from 'pinia'

export type State = {
  jwt: JWT | null;
  user: User | null;
}

export default defineStore({
  id: 'main',

  state: (): State => ({
    jwt: null,
    user: null,
  }),

  getters: {
    authenticated: state => state.jwt !== null,
  },

  actions: {
    set ({ jwt, user }: Partial<State>) {
      if (typeof jwt !== 'undefined') { this.jwt = jwt }
      if (typeof user !== 'undefined') { this.user = user }
    },

    unset () {
      this.jwt = null
      this.user = null
    },

    disableTrial () {
      if (this.user) {
        this.user.trialAvailable = false
      }
    },

    setLastOpenedReport (id: number) {
      if (this.user) {
        this.user.lastOpenedReport = id
        this.user.lastOpenedReportAt = new Date()
      }
    },

    clearLastOpenedReport () {
      if (this.user) {
        this.user.lastOpenedReport = null
        this.user.lastOpenedReportAt = null
      }
    },
  },
})
