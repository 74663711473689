<template>
    <VApp class="bg-surface-container">
        <RouterView />

        <template v-for="d in dialogs" :key="d.id">
            <VDialog
                :model-value="true"
                width="auto"
            >
                <VCard
                    :title="d.subject"
                    :text="d.message"
                    max-width="400"
                    prepend-icon="mdi-alert-box"
                >
                    <template #actions>
                        <VBtn
                            class="ms-auto"
                            rounded="lg"
                            text="Ok"
                            @click="remove(d)"
                        />
                    </template>
                </VCard>
            </VDialog>
        </template>

        <VSnackbar
            v-for="n in notifications"
            :key="n.id"
            :model-value="n.visible"
            :color="{
                'info': 'info',
                'success': 'success',
                'warning': 'warning',
                'danger': 'error',
            }[n.type]"
            :timeout="n.timeout"
            location="bottom end"
            @update:model-value="(v) => v ? null : removeNotification(n)"
        >
            {{ n.message }}

            <template #actions>
                <VBtn
                    color="white"
                    icon="mdi-close"
                    variant="text"
                    @click="removeNotification(n)"
                />
            </template>
        </VSnackbar>
    </VApp>
</template>

<script lang="ts" setup>
import type {
  Alert,
  Dialog,
} from '@/injections/dialogs'

import type { Notification } from '@/injections/notifications'

import {
  nextTick,
  provide,
  ref,
} from 'vue'

import { showAlertInjectKey } from '@/injections/dialogs'
import { showNotificationInjectKey } from '@/injections/notifications'

let counter = 0

const dialogs = ref<Required<Alert>[]>([])

const remove = (dialog: Dialog) => {
  dialogs.value = dialogs.value.filter(d => d.id !== dialog.id)
}

provide(showAlertInjectKey, (alert: Omit<Alert, 'id'>) => {
  dialogs.value.push({
    id: ++counter,
    subject: 'Important note',
    ...alert,
  })
})

const notifications = ref<Required<Notification>[]>([])

const removeNotification = (notification: Notification) => {
  notification.visible = false
  nextTick(() => {
    notifications.value = notifications.value.filter(n => n.id !== notification.id)
  })
}

provide(showNotificationInjectKey, (notification: Omit<Notification, 'id' | 'visible'>) => {
  const id = ++counter

  notifications.value.push({
    type: 'info',
    timeout: 3000,
    ...notification,
    id,
    visible: false,
  })

  nextTick(() => {
    const n = notifications.value.find(n => n.id === id)
    if (n) {
      n.visible = true
    }
  })
})
</script>
