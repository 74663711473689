import type { Plugin } from 'vue'
import type { Router } from 'vue-router'

import type { User } from '@/domain/user.ts'

import * as Sentry from '@sentry/vue'

export const setUser = (user: User | null) => {
  if (user) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.fullName,
      isStaff: user.isStaff,
    })
  } else {
    Sentry.setUser(null)
  }
}

export default {
  install: (app, router) => {
    if (process.env.SENTRY_DSN && process.env.NODE_ENV === 'production') {
      Sentry.init({
        app,
        dsn: process.env.SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
        ],
        tracesSampleRate: 0.1,
        ignoreErrors: [
          /firefoxSample/, // OrangeMonkey
        ],
        beforeSend: (event) => {
          const exceptions = event.exception?.values ?? []
          const stacktrace = exceptions[0]?.stacktrace

          try {
            const frames = stacktrace?.frames ?? []
            const filename = frames[0]?.filename ?? ''
            if (filename === '<anonymous>' || filename.match(/chrome-extension/)) {
              return null
            }
          } catch (e) { /* Empty */ }

          return event
        },
        release: process.env.RELEASE,
      })
    }
  },
} satisfies Plugin<Router>
