export type Predicate = (value: unknown) => boolean

export const isArray = Array.isArray

export const isShape = <
  S extends Record<string, [Predicate, boolean] | Predicate>
>(shape: S): Predicate => {
  const properties = Object.keys(shape)

  return (value: unknown) => typeof value === 'object' && value !== null && properties.every((p) => {
    const config = shape[p]
    const [predicate, required] = isArray(config) ? config : [config, false]
    if (!(p in value)) {
      return !required
    }

    return predicate(value[p as keyof typeof value])
  })
}

export const isString = (value: unknown): value is string => typeof value === 'string'
