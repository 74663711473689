import type { JWT } from '@/api/client'
import type { User } from '@/domain/user'

const storeName = 'credentials'

interface EntityMap {
  jwt: JWT | null;
  user: User | null;
}

type EntityKey = keyof EntityMap

export const get = async <K extends EntityKey>(key: K): Promise<EntityMap[K]> => {
  const transaction = await openTransaction(storeName, 'readonly')
  const store = transaction.objectStore(storeName)

  const request = store.get(key)

  return new Promise<EntityMap[K]>((resolve, reject) => {
    request.onsuccess = () => resolve(request.result ?? null)
    request.onerror = reject
  })
}

export const put = async <V extends Partial<EntityMap>>(values: V) => {
  const transaction = await openTransaction(storeName, 'readwrite')
  const store = transaction.objectStore(storeName)

  store.clear().onsuccess = () => {
    Object.keys(values).forEach((key) => {
      store.put(values[key as EntityKey], key)
    })
  }

  return new Promise((resolve, reject) => {
    transaction.oncomplete = resolve
    transaction.onerror = reject
    transaction.onabort = reject
  })
}

export const clear = async () => {
  const transaction = await openTransaction(storeName, 'readwrite')
  const store = transaction.objectStore(storeName)

  store.clear()

  return new Promise((resolve, reject) => {
    transaction.oncomplete = resolve
    transaction.onerror = reject
    transaction.onabort = reject
  })
}

async function openTransaction (name: string, mode: IDBTransactionMode) {
  const db = await openDb()

  return db.transaction(name, mode)
}

async function openDb () {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request: IDBOpenDBRequest = indexedDB.open('peakpicks', 1)

    request.onupgradeneeded = () => {
      const db: IDBDatabase = request.result
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { autoIncrement: true })
      }
    }

    request.onsuccess = () => resolve(request.result)
    request.onerror = reject
  })
}
