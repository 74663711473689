import type {
  JWT,
} from './authorization'

import type {
  Maybe,
  Unknown,
} from './scaffolding'

import {
  FetchError,
  createErrorAugmenter,
  fromResponse,
} from './errors'

export interface ClientInterface {
  jwt: JWT | null;

  get (url: string, params?: URLSearchParams): Promise<Response>;
  post<T extends Unknown> (
    url: string,
    body?: Maybe<T>
  ): Promise<Response>;
}

export class Client implements ClientInterface {
  private readonly _url: string
  private _jwt: JWT | null = null

  constructor (url: string) {
    this._url = url
  }

  get jwt (): JWT | null { return this._jwt }
  set jwt (jwt: JWT | null) { this._jwt = jwt }

  async get (url: string, params?: URLSearchParams) {
    const augment = createErrorAugmenter()

    try {
      const fullUrl = params ? `${url}?${params}` : url
      return await this._fetch(fullUrl, { method: 'GET' })
    } catch (e: unknown) {
      throw augment(e)
    }
  }

  async post <T extends Unknown>(url: string, body: Maybe<T> = undefined) {
    const augment = createErrorAugmenter()

    try {
      return await this._fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        ...(
          body !== undefined
            ? { body: JSON.stringify(body) }
            : {}
        ),
      })
    } catch (e: unknown) {
      throw augment(e)
    }
  }

  private async _fetch (url: string, init: Maybe<RequestInit> = undefined) {
    let response: Response

    try {
      response = await fetch(`${this._url}${url}`, {
        ...init,
        headers: {
          ...init?.headers,
          Accept: 'application/json',
          ...(this.jwt ? { authorization: `JWT ${this.jwt.access}` } : {}),
        },
      })
    } catch (e: unknown) {
      throw new FetchError(e instanceof Error ? e.message : String(e))
    }

    if (!response.ok) {
      throw await fromResponse(`${this._url}${url}`, response)
    }

    return response
  }
}
