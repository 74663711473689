import type {
  JWT,
} from './authorization'

import type {
  ClientInterface,
} from './client'

import type {
  Maybe,
  Unknown,
} from './scaffolding'

import {
  Client,
} from './client'

import {
  FetchError,
  HttpError,
  BadRequestHttpError,
  UnauthorizedHttpError,
  ForbiddenHttpError,
  NotFoundHttpError,
  ServerError,
} from './errors'

import {
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
} from './statuses'
import * as db from '@/db.ts'

export type {
  ClientInterface,
  JWT,
}

export {
  Client,
  FetchError,
  HttpError,
  BadRequestHttpError,
  UnauthorizedHttpError,
  ForbiddenHttpError,
  NotFoundHttpError,
  ServerError,
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  url,
}

export class RefreshableClient implements ClientInterface {
  private readonly _client: ClientInterface
  private readonly _refresher: ClientInterface

  constructor (client: ClientInterface, refresher: ClientInterface) {
    this._client = client
    this._refresher = refresher
  }

  get jwt (): JWT | null { return this._client.jwt }
  set jwt (jwt: JWT | null) { this._client.jwt = jwt }

  async get (url: string, params?: URLSearchParams) {
    return this._exec(() => this._client.get(url, params))
  }

  async post <T extends Unknown>(url: string, body: Maybe<T> = undefined) {
    return this._exec(() => this._client.post(url, body))
  }

  private async _exec (operation: () => Promise<Response>) {
    try {
      return await operation()
    } catch (e) {
      if (e instanceof UnauthorizedHttpError && await this._refresh()) {
        return await operation()
      }

      throw e
    }
  }

  private async _refresh () {
    const refresh = this.jwt?.refresh ?? null
    if (refresh) {
      const response = await this._refresher.post('/user/refresh_jwt/', {
        refresh,
      })

      const raw = await response.json()
      if ('access' in raw && typeof raw.access === 'string') {
        this.jwt = { access: raw.access, refresh }
        const user = await db.get('user')
        await db.put({
          user,
          jwt: { access: raw.access, refresh },
        })
        return true
      }
    }

    return false
  }
}

const url = `${process.env.BACKEND_URL}/api`

export default new RefreshableClient(
  new Client(url),
  new Client(url)
) as ClientInterface
