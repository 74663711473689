import client from './client'

export default async (data: {
  rating: number;
  reportId: number;
  message: string;
}) => {
  await client.post('/report-feedback/send/', {
    rating: data.rating,
    report_id: data.reportId,
    message: data.message,
  })
}
