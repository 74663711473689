import { inject } from 'vue'

export interface Notification {
  id: number;
  type?: 'info' | 'success' | 'warning' | 'danger';
  message: string;
  timeout?: number;
  visible: boolean;
}

export type ShowNotification = (notification: Omit<
  Notification,
  | 'id'
  | 'visible'
>) => void

export const showNotificationInjectKey = Symbol('showNotification')

export const useShowNotification = (): ShowNotification => {
  return inject<ShowNotification>(showNotificationInjectKey, () => {})
}
