import { inject } from 'vue'

export interface Dialog {
  id: number;
}

export interface Alert extends Dialog {
  id: number;
  subject?: string;
  message: string;
}

export type ShowAlert = (alert: Omit<Alert, 'id'>) => void

export const showAlertInjectKey = Symbol('showAlert')

export const useShowAlert = (): ShowAlert => {
  return inject<ShowAlert>(showAlertInjectKey, () => {})
}
