export interface Field<T> {
  value: T;
  errors: string[];
  validate (): void;
}

export interface FieldChoice<T> extends Field<T> {
  options: Array<{
    value: T;
    label: string;
  }>;
}

export interface FieldPassword extends Field<string> {
  hidden: boolean;
}

export const validate = (fields: Field<unknown>[]) => {
  fields.forEach(f => f.validate())
  return fields.every(f => f.errors.length === 0)
}
