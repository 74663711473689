<template>
    <VLayout>
        <VNavigationDrawer
            v-model="navigation.opened"
            :mobile="breakpoint.lt('t-md')"
            :rail="breakpoint.ge('t-md')"
            :style="{
                ...(breakpoint.ge('t-md') && (navigation.hovered || navigation.submenu) ? {
                    width: '256px',
                } : {}),
            }"
            class="position-fixed"
            color="primary-navigation"
            rail-width="80"
            @mouseenter="navigation.hovered = true"
            @mouseleave="navigation.hovered = false"
        >
            <template #prepend>
                <div
                    v-if="breakpoint.ge('t-md')"
                    :class="$style['logo']"
                    aria-label="PeakPicks"
                >
                    <AppLogoSymbol
                        :class="$style['logo__symbol']"
                        aria-hidden="true"
                    />

                    <VFadeTransition>
                        <AppLogoText
                            v-if="navigation.hovered || navigation.submenu || breakpoint.lt('t-md')"
                            :class="$style['logo__text']"
                            aria-hidden="true"
                        />
                    </VFadeTransition>
                </div>

                <div v-else class="px-1 py-4">
                    <VBtn
                        aria-label="Close"
                        color="white"
                        icon="mdi-close"
                        variant="text"
                        rounded="50%"
                        @click="navigation.opened = false"
                    />
                </div>
            </template>

            <VList nav>
                <VListItem
                    :to="{ name: ROUTES.UNRESTRICTED_SOURCING_REQUEST }"
                    prepend-icon="mdi-home"
                    title="Unrestricted Product Sourcing"
                    class="pl-5"
                    exact
                />

                <VListItem
                    :to="{ name: ROUTES.SINGLE_ASIN_REQUEST }"
                    prepend-icon="mdi-creation"
                    title="Sales & ROI Analyzer"
                    class="pl-5"
                    exact
                >
                    <VChip
                        class="position-absolute"
                        color="app-orange-2"
                        size="small"
                        style="top: -10px; right: -7px;"
                        text="Free"
                        variant="flat"
                    />
                </VListItem>

                <VListItem
                    :to="{ name: ROUTES.AI_FINDER_REQUEST }"
                    prepend-icon="mdi-file-eye-outline"
                    title="AI Profit Finder"
                    class="pl-5"
                    exact
                />

                <VListItem
                    :to="{ name: ROUTES.REPORT_LIST }"
                    prepend-icon="mdi-file-chart-outline"
                    title="Reports"
                    class="pl-5"
                />
            </VList>

            <template #append>
                <VList nav>
                    <VListItem
                        :to="{ name: ROUTES.PRICING }"
                        prepend-icon="mdi-currency-usd"
                        title="Upgrade"
                        class="pl-5"
                        exact
                    />

                    <VListItem
                        :to="{ name: ROUTES.SUPPORT }"
                        prepend-icon="mdi-message-question"
                        title="Contact support"
                        class="pl-5"
                        exact
                    />
                </VList>

                <VDivider color="white" />

                <VList v-if="store.user">
                    <VMenu v-model="navigation.submenu" location="end">
                        <template #activator="{ props }">
                            <VListItem
                                :title="store.user.fullName"
                                :subtitle="store.user.email"
                                v-bind="props"
                                prepend-icon="mdi-account-box"
                                class="pl-7"
                            />
                        </template>

                        <VList class="bg-primary-navigation">
                            <VListItem
                                :to="{ name: ROUTES.SETTINGS }"
                                prepend-icon="mdi-cogs"
                                title="Settings"
                                exact
                            />

                            <VListItem
                                prepend-icon="mdi-exit-run"
                                title="Log out"
                                @click="logout"
                            />
                        </VList>
                    </VMenu>
                </VList>
            </template>
        </VNavigationDrawer>

        <VAppBar
            v-if="breakpoint.lt('t-md')"
            :class="[$style['top'], 'position-fixed']"
            color="primary-navigation"
            height="80"
            style="z-index: 100"
            flat
        >
            <template #prepend>
                <VAppBarNavIcon
                    color="white"
                    @click="navigation.opened = true"
                />
            </template>

            <AppLogo :class="$style['top__logo']" />
        </VAppBar>

        <VMain>
            <VContainer class="pa-4 pa-sm-8" fluid>
                <RouterView />
                <VDialog v-model="contactUsDialogOpen" width="auto" persistent>
                    <VCard
                        tag="form"
                        max-width="600"
                        min-width="480"
                        @submit.prevent="submit"
                    >
                        <template v-if="submitted" #title>
                            ✅ Success
                        </template>

                        <template v-else #title>
                            Did you like the report you just viewed?
                        </template>

                        <template v-if="submitted" #text>
                            <div class="text-high-emphasis mb-3">
                                Thank you for the feedback!
                            </div>

                            <VBtn
                                size="large"
                                rounded="lg"
                                text="Close"
                                exact
                                @click="submitted = false"
                            />
                        </template>

                        <template v-else #text>
                            <div class="mb-3">
                                Rate report 1-5
                                <VSelect
                                    v-model="rating.value"
                                    :items="rating.options"
                                    :error-messages="rating.errors"
                                    :density="breakpoint.ge('t-md') ? 'default' : 'compact'"
                                    :max-width="breakpoint.lt('t-sm') ? undefined : '600px'"
                                    class="mb-3"
                                    item-title="label"
                                    item-value="value"
                                    @blur="rating.validate"
                                />
                                <div v-if="rating.value != null && rating.value != 0 && rating.value < 4">
                                    Please tell us why
                                </div>
                                <VTextarea
                                    v-if="rating.value != null && rating.value != 0 && rating.value < 4"
                                    v-model="question.value"
                                    :error-messages="question.errors"
                                    label="Your opinion"
                                    max-rows="16"
                                    max-width="600"
                                    @blur="question.validate"
                                />
                            </div>

                            <div class="mb-8">
                                <VBtn
                                    :loading="submitting"
                                    type="submit"
                                    size="large"
                                    rounded="lg"
                                    text="Submit"
                                />
                            </div>
                        </template>
                    </VCard>
                </VDialog>
            </VContainer>
        </VMain>
    </VLayout>
</template>

<script lang="ts" setup>
import AppLogo from '@/assets/sprites/logo.svg'
import AppLogoSymbol from '@/assets/sprites/logo-symbol.svg'
import AppLogoText from '@/assets/sprites/logo-text.svg'

import { ServerError, UnauthorizedHttpError } from '@/api/client'

import {
  onErrorCaptured,
  reactive, ref, watch,
} from 'vue'

import { useBreakpoint } from '@/composables/breakpoint.ts'
import useMainStore from '@/store/main'

import { ROUTES } from '@/router'
import { Field, type FieldChoice, validate } from '@/forms'
import { useShowAlert } from '@/injections/dialogs.ts'
import sendReportFeedback from '@/api/sendReportFeedback.ts'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const breakpoint = useBreakpoint()
const navigation = reactive({
  hovered: false,
  opened: breakpoint.value.ge('t-md'),
  submenu: false,
})

const store = useMainStore()
const route = useRoute()

const showAlert = useShowAlert()
const contactUsDialogOpen = computed(() => {
  const lastOpenedReportAtValid = store.user?.lastOpenedReportAt != null &&
    new Date(store.user.lastOpenedReportAt).getTime() > Date.now() - 1000 * 60 * 15
  const isNotReportPath = !route.path.includes('report/')

  return (lastOpenedReportAtValid && isNotReportPath) || submitted.value
})

const question = reactive({
  value: '',
  errors: [],
  validate: () => {
    switch (true) {
      case question.value.length === 0 && rating.value != null && rating.value != 0 && rating.value < 4:
        question.errors = ['This field is required']
        return
      default:
        question.errors = []
    }
  },
} as Field<string>)

watch(() => question.value, () => question.errors = [])

const submitting = ref(false)
const submitted = ref(false)

const submit = async () => {
  if (!validate([question, rating])) {
    return
  }

  try {
    submitting.value = true

    await sendReportFeedback({
      rating: rating.value!,
      message: question.value,
      reportId: Number(store.user?.lastOpenedReport),
    })
    submitted.value = true
    store.clearLastOpenedReport()
  } catch (e) {
    if (e instanceof ServerError) {
      showAlert({
        subject: 'Server error',
        message: 'Something went wrong while trying to send the request. ' +
        'But don\'t worry, we are already aware and will fix it as soon as possible!',
      })
      return
    }

    throw e
  } finally {
    submitting.value = false
  }
}

const rating = reactive({
  value: 0,
  errors: [],
  options: [
    { value: 0, label: 'Choose Value' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],
  validate: () => {
    switch (true) {
      case rating.value === null || rating.value === 0:
        rating.errors = ['This field is required']
        return
      default:
        rating.errors = []
    }
  },
} as FieldChoice<0 | null>)

const logout = () => store.set({
  jwt: null,
  user: null,
})

onErrorCaptured((e) => {
  if (e instanceof UnauthorizedHttpError) {
    logout()
  }
})
</script>

<style lang="scss" module>
@use '../stylesheets/basics/colors';

.top {
  :global(.v-toolbar__content) {
    border-bottom: 1px solid rgba(var(--v-theme-on-primary-navigation), var(--v-border-opacity));
  }

  &__logo {
    width: 120px;
    height: 24px;
    color: colors.$green-2;
    margin-right: 16px;
  }
}

.logo {
  display: flex;
  width: 100%;
  padding: 24px;
  color: #DFFF72;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  &__symbol {
    display: inline-block;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  &__text {
    display: inline-block;
    height: 24px;
    flex-shrink: 0;
  }
}
</style>
